import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    RegisterCall,
    BodyContainer,
    Banner,
    BodyContent,
    FormContainer,
    ButtonContent,
    Content
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoLogin, DoRegister } from "services/authentication";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import Footer from "components/Footer";
import { Background, FormSpacer, FormTitle, Title } from "ui/styled";
import InfoData from "components/InfoData";
import { Container } from "reactstrap";
import RegisterForm from "components/RegisterForm";
import PlansCard from "components/Cards/PlansCard";
import Success from "components/Success";
import Appearance from "components/Appearance";
import ServicesOffered from "components/ServicesOffered";
import Payment from "components/Payment";
import { isEmail } from "utils/validators";
import UploadAndPreview from "components/UploadAndPreview";
import { Create, Read } from "services/core";
import { CoreContext } from "context/CoreContext";
import { UpdateMe } from "services/me";

export default function RegisterEscort() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { setUser } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [infoOption, setInfoOption] = useState('Personal data')
    const [success, setSuccess] = useState(null)
    
    const [preuser, setPreuser] = useState(null)
    const [video360, setVideo360] = useState(null); 
    const [imagesReview, setImagesReview] = useState([]); 
    
    const [services, setServices] = useState([]); 
    const [ethnicity, setEthnicity] = useState([]); 
    const [aboutme, setAboutme] = useState(""); 
    
    const [options, setOptions] = useState(null); 

    const contentRef = useRef(null);

    const [form, setForm] = useState({}) 

    const valid = (payload) => {

        if(!payload?.name){
            toast.error("Fill name field")
            return false;
        }

        if(!isEmail(payload?.email)){
            toast.error("Invalid email")
            return false;
        }

        if(payload?.password !== payload?.password){
            toast.error("Password and confirmation not match")
            return false;
        }

        return true
    }

    const action = async (payload) => {
        if (!valid(payload)) { return; }
        setLoading(true)

        const result = await DoRegister({
            ...payload,
            username: payload?.email?.replace(/ /g, ''),
            email: payload?.email?.replace(/ /g, ''),
            confirmed: true,
            blocked: false,
            role: 3,
            plan: 1
        })
        
        if (result && !exposeStrapiError(result)) {
            completeLogin(payload)
        } else {
            setLoading(false)
        }
    }

    const nextToService = async () => {
        
        if(!video360){
            toast.error("Fill 360 video")
            // return;
        }
        
        if( imagesReview?.length < 4 ){
            toast.error("Minimum 4 photos")
            // return;
        }
        
        handleHeaderInfo('Services offered')
    }

    const saveProfile = async () => {
        const payload = {
            services: services?.map(m => m?.id),
            region: ethnicity,
            video360: video360?.id,
            videos: [video360?.id],
            photos: imagesReview?.map(m => m?.id),
            user: preuser?.user?.id,
            about_me: aboutme,
            description: aboutme,
            ...form
        }
        
        setLoading(true)
        const result = await Create("models", { data:payload })
        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            await UpdateMe({ image: imagesReview?.[0]?.id, model: result?.data?.id })
            await Create("welcome", { name:preuser?.user?.name, email:preuser?.user?.email })
            handleSuccess()
        }
    }

    const handleHeaderInfo = (info) => {
        setInfoOption(info)
    }
    
    const completeLogin = async (payload) => {
        
        setLoading(true)
        const result = await DoLogin({ ...payload, identifier: payload.email?.replace(/ /g, '') })
        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            setPreuser(result)
            // if (result?.user) { setUser(result.user) }
            handleHeaderInfo('Appearance')
        }

    }

    const data = [
        { title: 'Personal data' },
        { title: 'Appearance' },
        { title: 'Services offered' },
        { title: 'Payment' },
    ]

    const registerFormItems = useMemo(() => {
        return [
            {
                ref: 'name',
                placeholder: 'Name',
                type: 'text',
                full: true,
                outline: true,
            },
            {
                ref: 'email',
                placeholder: 'Email',
                type: 'text',
                full: true,
                outline: true,
            },
            {
                password: true,
                ref: 'password',
                placeholder: 'Password',
                type: 'password',
                full: true,
                outline: true,
            },
            {
                ref: 'cpassword',
                placeholder: 'Confirm password',
                type: 'password',
                full: true,
                outline: true,
            },
        ]
    }, [])

    const registerTitles = useMemo(() => {
        return {
            'Personal data': {
                title: 'Fill in your details',
                text: 'Model registration'
            },
            'Appearance': {
                title: 'Fill in your details',
                text: 'Appearance'
            },
            'Services offered': {
                title: 'Fill in your details',
                text: 'Services offered'
            },
            'Payment': {
                title: 'Fill in your details',
                text: 'Buy highlights'
            }
        }
    }, [])

    const handleSuccess = () => {

        if (preuser?.user) { setUser(preuser.user) }

        setSuccess({
            title: 'Registration completed successfully',
            text: "Take the opportunity to highlight your profile, invest in credits to stay on top.",
            icon: 'email-big',
            // buttons: [
            //     {
            //         text: 'Want to buy later',
            //         action: () => navigate(''),
            //         rightIcon: 'chevron-white',
            //         color: 'borderBackground',
            //         between: true

            //     },
            //     {
            //         text: 'I want to buy credits now',
            //         action: () => navigate('purchase-of-credits'),
            //         outlineGradient: true,
            //         rightIcon: 'chevron-right',
            //         between: true,
            //     },
            // ]
        })
    }

    const init = async () => {
        const rs = await Read("services")
        const rr = await Read("regions")
        const rc = await Read("cities")
        
        const nrs = normalizeStrapiList(rs)
        const nrr = normalizeStrapiList(rr)
        const nrc = normalizeStrapiList(rc)
        const nop = { services: nrs, regions: nrr, cities:nrc }

        // console.log("setOptionX", nop)
        setOptions(nop)
    }

    useEffect(() => { init() ;}, [])

    return (
        <>
            <ContainerUnauthenticated keep background={success ? '/images/success.png' : ''} scrollTo={infoOption}>
                {!success ? null : <Success {...success} />}
                {
                    success ? null : <>
                        <BodyContainer >
                            <Background />
                            <BodyContent>
                                <Container>
                                    <FormTitle>{registerTitles?.[infoOption]?.title}</FormTitle>
                                    <Title nomargin>{registerTitles?.[infoOption]?.text}</Title>
                                    <FormSpacer small />
                                </Container>
                                <InfoData data={data} active={infoOption} />
                                {
                                    infoOption !== 'Personal data' ? null :
                                        <>
                                            <RegisterForm loading={loading} items={registerFormItems} action={action} />
                                        </>
                                }
                                {
                                    infoOption !== 'Appearance' ? null :
                                        <>
                                            <Content>
                                                <Title small maxwidth={289}>Now it's time to report your appearance</Title>
                                                <Appearance uploadedFile={video360} setUploadedFile={setVideo360} />
                                                <UploadAndPreview setUploadedFile={setImagesReview} />
                                                <ButtonContent width='531px'>
                                                    <Button outlineGradient rightIcon={'chevron-right'} onClick={nextToService} between >Advance</Button>
                                                </ButtonContent>
                                            </Content>
                                        </>
                                }
                                {
                                    infoOption !== 'Services offered' ? null :
                                        <>
                                            <ServicesOffered options={options} active={services} setActive={setServices} ethnicity={ethnicity} setEthnicity={setEthnicity} aboutme={aboutme} setAboutme={setAboutme} superForm={setForm} registering />
                                            <ButtonContent width='631px'>
                                                <Button outlineGradient nospace rightIcon={'chevron-right'} onClick={() => handleHeaderInfo('Payment')} between >Advance</Button>
                                            </ButtonContent>
                                        </>
                                }
                                {
                                    infoOption !== 'Payment' ? null :
                                        <>
                                            <Payment loading={loading} action={saveProfile} />
                                        </>
                                }
                            </BodyContent>
                        </BodyContainer>
                        <Footer />
                    </>
                }
            </ContainerUnauthenticated>
        </>
    );
}