import { CoreContext } from 'context/CoreContext'
import React, { useContext, useState } from 'react'
import { FormText, Icon, Title } from 'ui/styled'
import { AgeVerification, CloseContainer, CompanyButtonContainer, ModalText, ModalTitle, PlanValue, PurchaseDetails, SubValue, TermContainer, Value } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import { Container } from 'reactstrap';

export default function ModalPurchaseDetails() {

  const { modal, setModal } = useContext(CoreContext)

  const [success, setSuccess] = useState(false)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    setSuccess(true)
  }

  return (
    <>
      <Wrapper center noClose>
        <PurchaseDetails>
          <CloseContainer>
            <Icon icon={'close-white'} onClick={close} />
          </CloseContainer>
          <Icon icon={success ? 'checked-success' : 'money-orange'} />
          {success ? null : <>
            <ModalTitle>Purchase details</ModalTitle>
            <Container className='noPadding'>

              <ModalText big white>{modal?.data?.title}</ModalText>
              <ModalText orange>
                You will have your ad featured for 1 day. After this period, you must reactivate your subscription.
              </ModalText>
              <ModalText borderBottom paddingBottom>You can pause your ad at any time before the deadline ends. This way the credits will also be paused for you to use whenever you want.</ModalText>
            </Container>
            <Container className='noPadding center' >
              <PlanValue>
                Plan value
              </PlanValue>
              <Value>{modal?.data?.value}</Value>
              <SubValue>200 francs</SubValue>
            </Container>
            <Button onClick={handleSave} outlineGradient width={'fit-content'} rightIcon={'chevron-right'}>
              Confirm payment
            </Button>
          </>
          }
          {
            !success ? null : <>
              <ModalTitle>
                Congratulations. Your {modal?.data?.title} featured plan has been successfully activated!
              </ModalTitle>
            </>
          }
        </PurchaseDetails>

      </Wrapper >
    </>
  )
}
