import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Icon, Title } from 'ui/styled'
import { AgeVerification, CompanyButtonContainer, Logo, ModalText, ModalTitle, TermContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';

export default function ModalAgeVerification() {

  const { modal, setModal, setAged } = useContext(CoreContext)

  const close = () => {
    setModal(null)
  }

  const agree = () => {
    setAged(true)
    close()
  }

  const cancel = () => {
    // window.goBack()
    window.location.href = "https://google.com"
  }

  return (
    <>
      <Wrapper noClose center>
        <AgeVerification>

          <Logo icon={"logo"} />
          <ModalTitle upper nomargin>Age verification</ModalTitle>
          <ModalText>This website contains content restricted to people over 18 years of age. By clicking 'Confirm', you are certifying that you are of legal age under the laws of your jurisdiction. If you are not of the required minimum age, please leave this site immediately.</ModalText>
          <ModalText>False age confirmation is a violation of this site's terms of use.</ModalText>
          <ModalText white big>Are you over 18<br /> years of age?</ModalText>
          <CompanyButtonContainer>
            <Button onClick={agree} outlineGradient>Yes</Button>
            <Button primary onClick={cancel} outlineGradient>No</Button>
          </CompanyButtonContainer>
          <TermContainer>
            <ModalText link white>Privacy Policy</ModalText>
            <ModalText link white>Terms of use</ModalText>
          </TermContainer>
        </AgeVerification>
      </Wrapper>
    </>
  )
}
