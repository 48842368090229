import styled from 'styled-components';

export const UserInfoContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const UserDetailContainer = styled.div.attrs({})`
  display: flex;
  gap: 24px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.palette.borderBackground.main};
  padding: 40px;
`;

export const ProfileImgContainer = styled.div.attrs({})`
  flex: 1;
`;
