import PaymentCard from 'components/Cards/PaymentCard';
import MyCredits from 'components/MyCredits';
import React, { useContext, useMemo } from 'react';
import Button from 'components/Form/Button';
import { CardsContainer, CreditsContainer } from './styled';
import EscortPlansCard from 'components/Cards/EscortPlans';
import { CoreContext } from 'context/CoreContext';

export default function Plans() {
  const { setModal } = useContext(CoreContext);


  const plansItem = useMemo(() => [
    {
      icon: 'basic',
      title: 'Basic Plan',
      subtitle: 'Featured for 1 day',
      text: 'You can have your ad featured for 1 day.',
      value: '1 credit',
      label: 'Choose plan',
      action: () => setModal({ type: 'purchasedetails', data: { title: 'Basic Plan', value: '1 credit' } }),
    },
    {
      icon: 'bronze',
      title: 'Bronze Plan',
      subtitle: 'Featured for 7 days',
      text: 'You can have your ad featured for 1 week.',
      value: '7 credits',
      label: 'Choose plan',
      action: () => setModal({ type: 'purchasedetails', data: { title: 'Bronze Plan', value: '7 credits' } }),
    },
    {
      icon: 'premium',
      title: 'Premium Plan',
      subtitle: 'Featured for 30 days',
      text: 'You can have your ad featured for 1 month.',
      value: '30 credits',
      label: 'Choose plan',
      action: () => setModal({ type: 'purchasedetails', data: { title: 'Premium Plan', value: '30 credits' } }),
    },

  ], []);

  return (
    <>
      <CreditsContainer>
        <MyCredits noChoose
          title={'Your credits'}
          subtitle={'Your credits have expired. You need to buy more credits to be featured on the platform.'}
          value={'30 credits'}
        />
        <CardsContainer>
          {
            plansItem?.map((item, index) => (
              <EscortPlansCard key={index} {...item} />
            ))
          }
        </CardsContainer>
      </CreditsContainer>
    </>
  );
}