import React from 'react'
import { FormBorder, PaymentBody, PaymentBorder, PaymentText, PaymentTitleContainer } from './styled'
import { Title } from 'ui/styled'
import Button from 'components/Form/Button'

export default function PaymentCard({ action, loading }) {
  return (
    <>
      <PaymentBorder>
        <PaymentBody>
          {/* <PaymentTitleContainer>
            <PaymentText>
              1 month - License
            </PaymentText>
            <Title nomargin>
              59.90 francs
            </Title>
          </PaymentTitleContainer> */}
          <FormBorder />
          <Title nomargin>
            stripe
          </Title>
          <PaymentText>
            Payment for your subscription will be made via Stripe.
          </PaymentText>
          <Button between loading={loading} rightIcon={"chevron-right"} outlineGradient width={"202px"} nospace onClick={action}>
            Continue
          </Button>
        </PaymentBody>
      </PaymentBorder>
    </>
  )
}
