import React, { useEffect, useMemo, useState } from 'react'
import { ButtonEditing, CheckContainer, FormContainer, InputContainer, NoteContainer, NoteText, ServiceHeaderContent, ServicesContainer } from './styled';
import { FormSpacer, Icon, Title } from 'ui/styled';
import Check from 'components/Form/Check';
import Select from 'components/Form/Select';
import Input, { MaskedInput } from 'components/Form/Input';

export default function ServicesOffered({ note, editing, noteEditing, options, active, setActive, ethnicity, setEthnicity, aboutme, setAboutme, superForm, sobs, saveNote, saveServices, registering }) {

  const [isEditing, setIsEditing] = useState(false)
  const [isNoteEditing, setIsNoteEditing] = useState(false)

  const [form, setForm] = useState({})
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const handleActive = (item) => {
    if(!setActive || typeof setActive !== 'function') return ;
    if (active?.map(m => m?.id)?.includes(item?.id)) {
      setActive(prev => prev.filter(f => f?.id !== item?.id))
      return;
    }
    setActive(prev => [...prev, item])
  }

  const handleEdit = () => {
    if(isEditing){
      if(saveServices && typeof saveServices === 'function'){ saveServices() ;}
    }
    setIsEditing(prev => !prev)
  }

  const handleNoteEdit = () => {
    if(isNoteEditing){
      if(saveNote && typeof saveNote === 'function'){ saveNote() ;}
    }
    setIsNoteEditing(prev => !prev)
    if(!isNoteEditing){
      setTimeout(() => { document.getElementById("note").focus() }, 100)
    }
  }

  useEffect(() => {
    if(superForm && typeof superForm === 'function'){ superForm(form) ;}
  }, [form])


  useEffect(() => { setForm({ ...form, service_observations: sobs }) ;}, [sobs])

  return (
    <>
      <ServicesContainer>

        {note ? null : <FormContainer>
          <InputContainer>
            <Input type='date' noHolder outline placeholder="Birthdate" value={formValue('birthdate')} onChange={e => changeForm(e.target.value, 'birthdate')} />
            <MaskedInput mask={"99999999999"} noHolder outline placeholder="Phone" value={formValue('phone')} onChange={e => changeForm(e.target.value, 'phone')} />
            <MaskedInput mask={"99999999999"} noHolder outline placeholder="Whatsapp" value={formValue('whatsapp')} onChange={e => changeForm(e.target.value, 'whatsapp')} />
            <MaskedInput mask={"99999999999"} noHolder outline placeholder="Telegram" value={formValue('telegram')} onChange={e => changeForm(e.target.value, 'telegram')} />
          </InputContainer>
        </FormContainer>
        }

        <ServiceHeaderContent between={editing}>
          <Title small>
            What services do you offer?
          </Title>
          {editing ?
            <ButtonEditing onClick={handleEdit}>
              <Icon icon={isEditing ? 'save' : 'pencil'} />
            </ButtonEditing>
            : null}
        </ServiceHeaderContent>
        <CheckContainer>
          {
            (options?.services||[])?.map((m, k) => (
              <Check label={m?.title} key={k} disabled={!isEditing && !registering} checked={active?.map(mm => mm?.id)?.includes(m?.id) || m?.active} onChange={() => handleActive(m)} />
            ))
          }
        </CheckContainer>
        {note ? null : <FormContainer>
        <FormSpacer />
          <InputContainer>
            <Select borderBackground placeholder='Canton' options={options?.regions || []} onChange={setEthnicity} value={ethnicity} />
            <Select borderBackground placeholder="Ville" options={(options?.cities || [])?.filter(m => `${m?.region?.data?.id}` === `${ethnicity}`) } value={formValue('city')} onChange={value => changeForm(value, 'city')} />
            <Input noHolder outline placeholder="Describe yourself below:" type='textarea' textarea value={aboutme} onChange={e => setAboutme(e.target.value)} />
          </InputContainer>
        </FormContainer>
        }
        {
          !note ? null : <>
            <NoteContainer noteEditing={noteEditing}>
              <NoteText>
                <Input id={"note"} noHolder disabled={!isNoteEditing} outline placeholder="Note:" type='textarea' textarea  value={formValue('service_observations')} onChange={e => changeForm(e.target.value, 'service_observations')} />
              </NoteText>
              {noteEditing ?
                <ButtonEditing onClick={handleNoteEdit}>
                  <Icon icon={isNoteEditing ? 'save' : 'pencil'} />
                </ButtonEditing>
                : null}
            </NoteContainer>
          </>
        }
      </ServicesContainer>

    </>
  )
}
