import ContainerAuthenticated from 'containers/Authenticated'
import React from 'react'
import { AnnouncementContainer, AnnouncementContent, AnnouncementDescription, AnnouncementImage, AnnouncementItem, AnnouncementSpace, AnnouncementTitle, AnnouncementTop, Subtitle, TitleContent } from './styled'
import { FormSpacer, FormTitle, Title } from 'ui/styled'
import AnnouncementSection from 'components/AnnouncementSection'
import Footer from 'components/Footer'
import Button from 'components/Form/Button'
import AnnouncementForm from 'components/AnnouncementForm'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function Announcement() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const announcement = [
    {
      title: 'Clean and intuitive interface',
      description: 'We offer a clean and intuitive interface so models can manage their ads efficiently and without complications. Our platform is designed to be easy to use, providing a stress-free and enjoyable ad management experience.',
      image: '/images/announcement2.png',
    },
    {
      title: 'Ad management options',
      description: 'Have full control over your ads with our create, edit and delete options. You can create new ads, edit existing information, and remove ads as needed, all in one place.',
      image: '/images/announcement3.png',
      reverse: true,
    },
    {
      title: 'Updating personal information',
      description: 'Keep your information up to date with ease. With our platform, you can update personal information like photos and description, ensuring your customers receive the latest information about your services.',
      image: '/images/announcement4.png',
    },
    {
      title: 'Highlight every 4 Hours',
      description: 'Stand out every 4 hours to maximize your visibility and attract more customers. With just one click, you can ensure your ad is displayed prominently, standing out among the rest for greater exposure.',
      image: '/images/announcement5.png',
      reverse: true,
    },
    {
      title: 'Buy highlights',
      description: 'Increase your visibility and reach by purchasing additional highlights for your ads. We offer flexible options so you can choose the plan that best suits your needs and budget, ensuring your profile gets the attention it deserves.',
      image: '/images/announcement6.png',
    },
  ]
  return (
    <>
      <ContainerAuthenticated free>
        <AnnouncementTop>
          <Title>
            Advertise here
          </Title>
          <Subtitle>
            Join us and enjoy a platform dedicated to promoting your security, visibility and success.
          </Subtitle>
        </AnnouncementTop>
        <AnnouncementSpace />
        <AnnouncementContainer>
          <TitleContent>
            <Title small>
              When you register with LUXEO, you will have access to a series of exclusive benefits, including:
            </Title>
          </TitleContent>
          <FormSpacer large />

          <AnnouncementSection />

          <TitleContent>
            <Title small>
              Do not waste time! Register now and embark on this successful journey with us at LUXEO.
            </Title>
            <Button width={'fit-content'} rightIcon={'chevron-right'} outlineGradient onClick={() => navigate('register/escort')}>
              Register now
            </Button>
          </TitleContent>
          <FormSpacer large />
          <AnnouncementContent>
            {
              announcement.map((item, index) => (
                <AnnouncementItem key={index} reverse={item?.reverse}>
                  <AnnouncementTitle>
                    <Title left>
                      {item.title}
                    </Title>
                    <AnnouncementDescription >
                      {item.description}
                    </AnnouncementDescription>
                  </AnnouncementTitle>
                  <AnnouncementImage image={item.image} />
                </AnnouncementItem>
              ))
            }
          </AnnouncementContent>
          <AnnouncementForm />
          <FormSpacer large />
        </AnnouncementContainer>
        <Footer />
      </ContainerAuthenticated>
    </>
  )
}
