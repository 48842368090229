import CreditsCard from 'components/Cards/Credits';
import PaymentCard from 'components/Cards/PaymentCard';
import MyCredits from 'components/MyCredits';
import React, { useMemo } from 'react';
import Button from 'components/Form/Button';
import { CardsContainer, CreditsContainer } from './styled';

export default function AdditionalCredits() {
  const creditDays = [
    { title: '1 day', value: '1 credit' },
    { title: '7 days', value: '7 credits' },
    { title: '30 days', value: '30 credits' },
  ];
  const creditInfo = [
    { title: '5 credits', value: '200 francs' },
    { title: '15 credits', value: '540 francs' },
    { title: '30 credits', value: '900 francs' },
  ];

  const creditItems = useMemo(() => [
    {
      title: 'How does the subscription work?',
      subtitle: 'You can subscribe for a specific time to be featured on the platform.',
      type: 'button',
      buttonText: '30 days',
      infoFooter: 'After this period, you need to renew your subscription so that your ads are highlighted on the platform and increase the chances of being hired by users.',
    },
    {
      title: 'Credits and periods',
      subtitle: 'For each day of featured ad, you will need 1 credit.',
      type: 'list',
      list: creditDays,
      infoFooter: 'For each day of featured ad, you will need 1 credit.',
    },
    {
      title: 'Purchase of credits',
      subtitle: 'Select how many credits you want to buy.',
      type: 'purchase',
      options: creditInfo,
      light: true,
    },
  ], []);

  return (
    <>
      <CreditsContainer>
        <MyCredits noChoose
          title={'Your credits'} subtitle={'Your credits have expired. You need to buy more credits to be featured on the platform.'} value={'0 credits'} noButton
        />
        <CardsContainer>
          {creditItems.map((item, index) => (
            <CreditsCard key={index} {...item} />
          ))}
        </CardsContainer>
        <PaymentCard action={() => null} />
      </CreditsContainer>
    </>
  );
}