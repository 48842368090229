import ContainerAuthenticated from 'containers/Authenticated'
import React, { useState } from 'react'
import { ButtonContent, Credits, CreditsContainer, CreditsContent, CreditsOption, CreditsOptions, CreditsValue, PurchaseDetails, SubTitle, TitleContainer } from './styled'
import { ButtonContainer, FormSpacer, FormTitle, Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import Payment from 'components/Payment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


export default function PurchaseCredits() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const [active, setActive] = useState(1)
  const options = [
    {
      id: 1,
      credits: '5 credits',
      value: 'CHF 89.90'
    },
    {
      id: 2,
      credits: '10 credits',
      value: 'CHF 169.90'
    },
    {
      id: 3,
      credits: '20 credits',
      value: 'CHF 299.90'
    },
    {
      id: 4,
      credits: '50 credits',
      value: 'CHF 699.90'
    },
    {
      id: 5,
      credits: '100 credits',
      value: 'CHF 1,299.90'
    }
  ]

  const handleSelect = (id) => {
    setActive(id)
  }
  return (
    <>
      <ContainerAuthenticated free >
        <CreditsContainer>
          <CreditsContent>
            <Title>
              Highlight your profile
            </Title>
            <PurchaseDetails>
              <TitleContainer>
                <Icon icon={'money'} />
                <FormTitle black>
                  Purchase of credits
                  <SubTitle>
                    Select how many credits you want to buy
                  </SubTitle>
                </FormTitle>
              </TitleContainer>
              <CreditsOptions>
                {
                  options.map(option => (
                    <CreditsOption key={option.id} >
                      <Credits active={active === option?.id}>
                        {option.credits}
                      </Credits>
                      <CreditsValue active={active === option?.id}>
                        {option.value}
                      </CreditsValue>
                      <ButtonContent>
                        <Button outline={active !== option?.id}
                          color={'info'}
                          white={active === option?.id}
                          width={'fit-content'} small
                          onClick={() => handleSelect(option?.id)}
                          leftIcon={active === option?.id ? 'checked' : ''}
                          primary={active === option?.id}
                        >
                          {active === option?.id ? 'Selected' : 'Select'}
                        </Button>
                      </ButtonContent>
                    </CreditsOption>
                  ))
                }
              </CreditsOptions>
            </PurchaseDetails>
            <Payment noText />
            <ButtonContainer center>
              <FormSpacer l />
              <Button between color={'borderBackground'} width={'459px'}
                onClick={() => navigate('')}
                rightIcon={'chevron-white'}>
                I want to buy later
              </Button>
            </ButtonContainer>
          </CreditsContent>
        </CreditsContainer>
      </ContainerAuthenticated >
    </>
  )
}
