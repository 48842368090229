import React, { useEffect, useMemo, useState } from 'react'
import AdvertsCard from 'components/Cards/AdvertsCard';
import { AdvertsCardContainer, AdvertsCardContent } from './styled';
import DashboardCard from 'components/Cards/DashboardCard';
import AdvertsDetails from '../AdvertsDetails';

export default function Adverts() {
  const [adId, setAdId] = useState(null);
  const escort = {
    id: 1,
    name: "Sofia Oliveira",
    emphasis: true,
    location: {
      city: "Florianópolis",
      state: "Santa Catarina"
    },
    urls: [
      '/images/escort3.png',
    ]
  }

  const dashboardData = useMemo(() => {
    return [
      {
        icon: 'check-success',
        title: 'Ad status',
        subtitle: 'Active',
      },
      {
        icon: 'megaphone',
        title: 'Do you want to pause this ad?',
        subtitle: 'When you pause an ad, its credits are also paused.',
        buttonText: 'Pause ad',
      },
    ];
  }, []);

  const handleAdClick = (id) => {
    setAdId(id);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [adId])

  return (
    <>
      <AdvertsCardContainer>
        {adId ? null :
          <>
            <AdvertsCard escort={escort} handleAdClick={handleAdClick} />
            <AdvertsCardContent>
              {dashboardData?.map((item, index) => (
                <DashboardCard
                  key={index}
                  full
                  {...item}
                />
              ))}
            </AdvertsCardContent>
          </>
        }
        {
          !adId ? null : <AdvertsDetails onSave={handleAdClick} />
        }
      </AdvertsCardContainer>
    </>
  )
}