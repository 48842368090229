import styledCmp from 'styled-components'  
 
import { styled } from '@mui/material/styles'; 
 
import Rating from '@mui/material/Rating'; 
  
export const MaterialRating = styled(Rating)(({ theme }) => ({  
    '& .MuiRating-iconEmpty': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
})); 